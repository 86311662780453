@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
}

h1,h2,h3,h4,h5 {
    font-weight:bold;
}